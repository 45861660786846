<script>
import omitBy from 'lodash-es/omitBy'
import { ref, computed, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import FeedControls from '/~/extensions/health/components/feed/feed-controls.vue'
import FeedView from '/~/extensions/health/components/feed/feed-view.vue'
import FeedList from '/~/extensions/health/components/feed-list.vue'
import Tabs from '/~/extensions/health/components/tabs.vue'
import { useArticles } from '/~/extensions/health/composables'
import { useEditablePage } from '/~/composables/cms/use-editable-page'

export default {
  name: 'health-lifestyle-library-feed',
  components: {
    FeedControls,
    FeedView,
    FeedList,
    Tabs,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { feed, categories, saved, queryState, getCategories } = useArticles()
    const { page } = useEditablePage()

    const feedControlsElement = ref(null)
    const type = computed(() => route.query?.type ?? 'articles')
    const search = ref(route.query?.search ?? '')
    const category = ref(route.query?.category ?? null)
    const params = computed(() => {
      return { search: search.value, category: category.value }
    })

    const tabs = ref([
      {
        label: 'Articles',
        route: { path: 'lifestyle-library', query: { type: 'articles' } },
      },
      {
        label: 'Saved Articles',
        route: { path: 'lifestyle-library', query: { type: 'saved' } },
      },
    ])

    function getData() {
      const query = { search: search.value }
      const savedFilters = {
        query: {
          ...query,
          include: saved.value.join(',') || 0,
        },
      }

      return feed.value.getData(
        type.value === 'saved' ? savedFilters : { query }
      )
    }

    function onCategoryChange(categories) {
      category.value = categories
      const filter = categories ? { categories } : {}

      feed.value.setFilters(filter)
      getData()
      setRoute()
    }

    function onSearch(value) {
      if (search.value !== value) {
        search.value = value
        getData()
        setRoute()
      }
    }

    function setRoute() {
      const query = omitBy(
        { search: search.value, category: category.value, type: type.value },
        (v) => !v
      )

      router.replace({ query })
      queryState.value = query
    }

    function reset() {
      feed.value.setFilters({})
      feedControlsElement.value.search = ''
      feedControlsElement.value.resetCategory()
      getData()
    }

    watch(
      type,
      () => {
        reset()
      },
      { deep: true }
    )

    onMounted(() => {
      getCategories()
      onCategoryChange(category.value)
    })

    return {
      page,
      feedControlsElement,
      tabs,
      onSearch,
      onCategoryChange,
      categories,
      params,
      type,
      feed,
    }
  },
}
</script>

<template>
  <div class="mx-auto max-w-7xl">
    <feed-view>
      <template #hero>
        <tabs :tabs="tabs" :type="type" class="sm:hidden" />

        <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
      </template>

      <template #tabs>
        <tabs :tabs="tabs" :type="type" class="hidden sm:block" />
      </template>

      <template #controls>
        <feed-controls
          ref="feedControlsElement"
          :categories="categories"
          :params="params"
          placeholder="Search for an article..."
          @category-update="onCategoryChange"
          @input="onSearch"
        />
      </template>

      <feed-list
        :type="type"
        :feed="feed"
        tile-route-name="hlth-lifestyle-library-detail"
      />
    </feed-view>
  </div>
</template>
